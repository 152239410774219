import tw from 'twrnc';
import {Text, View} from 'react-native';
import * as React from 'react';

export default function TextCenter({children}: {children: string}) {
  return (
    <View style={tw`flex flex-1 items-center justify-center`}>
      <Text>{children}</Text>
    </View>
  );
}
