import {ExerciseModel, ExercisesResponse} from '../../models/ExerciseModel';
import useSWR from 'swr';
import {swrFetcher} from '../../shared/fetcher';
import TextCenter from '../TextCenter';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import tw from 'twrnc';
import * as React from 'react';

export function ExerciseList({
  categoryId,
  onSelectExercise,
}: {
  categoryId: number;
  onSelectExercise: (exercise: ExerciseModel) => any;
}) {
  const {
    data: exercises,
    isLoading,
    error,
  } = useSWR<ExercisesResponse>(
    `exercises?category_id=${categoryId}`,
    swrFetcher,
  );

  if (isLoading) {
    return <TextCenter>Loading...</TextCenter>;
  }

  if (!exercises?.data || error) {
    return <TextCenter>Error fetching data</TextCenter>;
  }

  if (!exercises.data.length) {
    return <TextCenter>No data</TextCenter>;
  }

  return (
    <View style={tw`gap-4`}>
      {exercises.data.map((exercise: ExerciseModel) => (
        <TouchableOpacity
          key={exercise.id}
          onPress={() => onSelectExercise(exercise)}>
          <View style={tw`border rounded-3xl border-gray-300 overflow-hidden`}>
            <View
              style={tw`flex flex-row justify-end gap-2 w-full bg-gray-200 p-4`}>
              <Text style={tw`uppercase font-bold`}>
                {exercise.estimated_time} MIN
              </Text>
              <Text style={tw`uppercase font-bold`}>Kontunuuj</Text>
            </View>
            <View style={tw`flex flex-row p-4 bg-white gap-4`}>
              {exercise.id % 3 === 0 ? (
                <Image
                  style={tw`rounded-full w-24 h-24 border-4 border-white -mt-10`}
                  source={require('../../assets/exercise1.png')}
                />
              ) : null}
              {exercise.id % 3 === 1 ? (
                <Image
                  style={tw`rounded-full w-24 h-24 border-4 border-white -mt-10`}
                  source={require('../../assets/exercise2.png')}
                />
              ) : null}
              {exercise.id % 3 === 2 ? (
                <Image
                  style={tw`rounded-full w-24 h-24 border-4 border-white -mt-10`}
                  source={require('../../assets/exercise3.png')}
                />
              ) : null}
              {exercise.id % 3 === 3 ? (
                <Image
                  style={tw`rounded-full w-24 h-24 border-4 border-white -mt-10`}
                  source={require('../../assets/exercise4.png')}
                />
              ) : null}
              <View>
                <Text style={tw`uppercase font-bold`}>
                  {exercise.exercise_category.title}
                </Text>
                <Text>
                  Lekcja {exercise.number} - {exercise.title}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  );
}
