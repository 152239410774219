import tw from 'twrnc';
import {Text, TouchableOpacity} from 'react-native';
import * as React from 'react';
import {useState} from 'react';

export default function Checkbox() {
  const [active, setActive] = useState(false);

  return (
    <TouchableOpacity
      onPress={() => setActive(!active)}
      style={tw`w-8 h-8 rounded-full ${
        active ? 'bg-amber-300' : 'bg-gray-100'
      }`}>
      <Text style={tw`text-center text-blue-950`} />
    </TouchableOpacity>
  );
}
