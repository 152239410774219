import {Text, TouchableOpacity, View} from 'react-native';
import {translate} from '../shared/translation';
import * as React from 'react';
import tw from 'twrnc';
import {RootStackScreenProps} from '../constants/ScreenProps';
import env from '../constants/env';
import Icon from '../components/Icon';
import HeaderLogo from '../components/HeaderLogo';
import Background from '../components/Background';
import Container from '../components/Container';

export function StartScreen({navigation}: RootStackScreenProps<'Start'>) {
  return (
    <Background>
      <HeaderLogo
        showObject={true}
        onPress={() => navigation.navigate('Start')}
      />
      <Container>
        <Text style={tw`text-4xl font-bold text-white text-center mb-4`}>
          {translate('start.title')}
        </Text>
        <Text style={tw`text-white text-center mb-6`}>
          {translate('start.subtitle')}
        </Text>
        <TouchableOpacity
          style={tw`bg-white p-4 rounded-full w-9/12 mb-4`}
          onPress={() => navigation.navigate('Register')}>
          <Text style={tw`text-center text-blue-950`}>
            {translate('start.submit_button')}
          </Text>
        </TouchableOpacity>
        <View style={tw`flex flex-row gap-2`}>
          <Text>{translate('start.login_text')}</Text>
          <TouchableOpacity onPress={() => navigation.navigate('Login')}>
            <View style={tw` border-black border-b-2 pb-1`}>
              <Text>{translate('start.login_button')}</Text>
            </View>
          </TouchableOpacity>
          <Icon name="arrow-right" color="black" size={20} />
        </View>
        {env.REACT_APP_DEV ? (
          <TouchableOpacity
            style={tw`p-4 rounded-full w-9/12 mt-8`}
            onPress={() => navigation.navigate('Home')}>
            <Text style={tw`text-center text-black opacity-30`}>
              Go to Home
            </Text>
          </TouchableOpacity>
        ) : null}
      </Container>
    </Background>
  );
}
