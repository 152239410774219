import {RootStackScreenProps} from '../constants/ScreenProps';
import {Text, TextInput, TouchableOpacity, View} from 'react-native';
import {translate} from '../shared/translation';
import * as React from 'react';
import {useState} from 'react';
import tw from 'twrnc';
import {postFetcher} from '../shared/fetcher';
import Alert from '../components/Alert';
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderLogo from '../components/HeaderLogo';
import Icon from '../components/Icon';
import Background from '../components/Background';
import Container from '../components/Container';
import env from '../constants/env';

export function LoginScreen({navigation}: RootStackScreenProps<'Login'>) {
  const [email, setEmail] = useState(
    env.REACT_APP_DEV ? 'test@example.com' : '',
  );
  const [password, setPassword] = useState(env.REACT_APP_DEV ? 'password' : '');
  const [error, setError] = useState('');

  const onLogin = async () => {
    setError('');

    if (!email || !password) {
      return setError('Fill email and password');
    }

    const response = await postFetcher('auth/login', {
      email,
      password,
    });

    if (response.code !== 200) {
      return setError(response.data.message || 'Internal error');
    }

    // TODO: move to service
    await AsyncStorage.setItem('token', response.data.token);
    navigation.navigate('Home');
  };

  return (
    <Background>
      <HeaderLogo onPress={() => navigation.navigate('Start')} />
      <Container>
        <Text style={tw`text-4xl font-bold text-white mb-4`}>
          {translate('login.title')}
        </Text>
        {error ? <Alert>{error}</Alert> : null}
        <TextInput
          autoCapitalize="none"
          style={tw`border rounded-full border-white w-full text-center text-white p-3 mb-3`}
          placeholderTextColor="white"
          placeholder={translate('login.email_input')}
          onChangeText={text => setEmail(text)}
          value={email}
        />
        <TextInput
          autoCapitalize="none"
          secureTextEntry={true}
          style={tw`border rounded-full border-white w-full text-center text-white p-3 mb-3`}
          placeholderTextColor="white"
          placeholder={translate('login.password_input')}
          onChangeText={text => setPassword(text)}
          value={password}
        />
        <TouchableOpacity
          style={tw`bg-white p-4 rounded-full w-full mb-4`}
          onPress={() => onLogin()}>
          <Text style={tw`text-center text-blue-950`}>
            {translate('login.submit_button')}
          </Text>
        </TouchableOpacity>
        <Text style={tw`mb-3 text-white`}>{translate('global.or')}</Text>
        <TouchableOpacity
          style={tw`bg-blue-600 p-4 rounded-full w-full mb-4 opacity-40`}
          disabled={true}
          onPress={() => {}}>
          <Text style={tw`text-center text-white`}>
            {translate('login.login_facebook')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={tw`bg-red-800 p-4 rounded-full w-full mb-4 opacity-40`}
          disabled={true}
          onPress={() => {}}>
          <Text style={tw`text-center text-white`}>
            {translate('login.login_google')}
          </Text>
        </TouchableOpacity>
        <View style={tw`flex flex-row gap-2`}>
          <Text>{translate('login.register_text')}</Text>
          <TouchableOpacity onPress={() => navigation.navigate('Register')}>
            <View style={tw` border-black border-b-2 pb-1`}>
              <Text>{translate('login.register')}</Text>
            </View>
          </TouchableOpacity>
          <Icon name="arrow-right" color="black" size={20} />
        </View>
      </Container>
    </Background>
  );
}
