import * as React from 'react';
import {ReactElement} from 'react';
import tw from 'twrnc';
import {Image, TouchableOpacity, View} from 'react-native';
import {Circle, Svg} from 'react-native-svg';

export default function HeaderLogo({
  showObject = false,
  onPress,
}: {
  showObject?: boolean;
  onPress?: () => void;
}): ReactElement {
  return (
    <View style={tw`relative flex items-center w-full mb-6`}>
      <Svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        style={tw`absolute z-20 -left-8 top-32`}>
        <Circle
          opacity="0.3"
          cx="40"
          cy="40"
          r="30"
          stroke="#F5C72D"
          strokeWidth="17"
        />
      </Svg>
      <Svg
        width="140"
        height="140"
        viewBox="0 0 140 140"
        fill="none"
        style={tw`absolute z-20 -right-8 top-4`}>
        <Circle opacity="0.1" cx="70" cy="70" r="65" fill="#F5C72D" />
      </Svg>
      <View
        style={{
          maxWidth: 600,
          width: '100%',
          borderBottomLeftRadius: 200,
          borderBottomRightRadius: 200,
          backgroundColor: 'white',
          paddingBottom: 100,
          paddingTop: 80,
        }}>
        <TouchableOpacity onPress={onPress} style={tw`flex items-center `}>
          <Image
            source={require('../assets/logo.png')}
            style={{width: 180, height: 120}}
          />
          {showObject ? (
            <Image
              source={require('../assets/object.png')}
              style={tw`ml-10 h-32 w-32`}
            />
          ) : null}
        </TouchableOpacity>
      </View>
    </View>
  );
}
