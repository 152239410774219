import {RootStackScreenProps} from '../constants/ScreenProps';
import {Text, TouchableOpacity, View} from 'react-native';
import {translate} from '../shared/translation';
import * as React from 'react';
import tw from 'twrnc';
import Background from '../components/Background';
import Icon from '../components/Icon';
import Container from '../components/Container';
import HeaderLogoRight from '../components/HeaderLogoRight';

export function RegisterScreen({navigation}: RootStackScreenProps<'Register'>) {
  return (
    <Background>
      <HeaderLogoRight onPress={() => navigation.navigate('Start')} />
      <Container>
        <Text style={tw`text-2xl font-bold text-white text-center mb-4`}>
          {translate('register.title')}
        </Text>
        <TouchableOpacity
          style={tw`bg-white p-4 rounded-full w-full mb-4`}
          onPress={() => navigation.navigate('RegisterEmail')}>
          <Text style={tw`text-center text-blue-950`}>
            {translate('register.select_email')}
          </Text>
        </TouchableOpacity>
        <Text style={tw`mb-3 text-white`}>{translate('global.or')}</Text>
        <TouchableOpacity
          style={tw`bg-blue-600 p-4 rounded-full w-full mb-4 opacity-40`}
          disabled={true}
          onPress={() => navigation.navigate('Login')}>
          <Text style={tw`text-center text-white`}>
            {translate('register.select_facebook')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={tw`bg-red-800 p-4 rounded-full w-full mb-4 opacity-40`}
          disabled={true}
          onPress={() => navigation.navigate('Login')}>
          <Text style={tw`text-center text-white`}>
            {translate('register.select_google')}
          </Text>
        </TouchableOpacity>
        <View style={tw`flex flex-row gap-2`}>
          <Text>{translate('start.login_text')}</Text>
          <TouchableOpacity onPress={() => navigation.navigate('Login')}>
            <View style={tw` border-black border-b-2 pb-1`}>
              <Text>{translate('start.login_button')}</Text>
            </View>
          </TouchableOpacity>
          <Icon name="arrow-right" color="black" size={20} />
        </View>
      </Container>
    </Background>
  );
}
