import tw from 'twrnc';
import {ScreenTabIcon} from '../constants/ScreenTabIcon';
import Icon from '../components/Icon';
import {ExerciseListScreen} from './HomeTab/ExerciseListScreen';
import {translate} from '../shared/translation';
import {ExerciseShowScreen} from './HomeTab/ExerciseShowScreen';
import {PaymentsScreen} from './HomeTab/PaymentsScreen';
import {StatisticsScreen} from './HomeTab/StatisticsScreen';
import {AccountScreen} from './HomeTab/AccountScreen';
import * as React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {HomeTabParamList} from '../constants/ScreenProps';

const Tab = createBottomTabNavigator<HomeTabParamList>();

export function HomeTabs() {
  return (
    <Tab.Navigator
      screenOptions={({route}) => ({
        headerStyle: tw`bg-amber-400`,
        tabBarStyle: tw`bg-amber-400`,
        tabBarLabelStyle: tw`text-white`,
        tabBarActiveTintColor: 'white',
        tabBarInactiveTintColor: 'white',
        // eslint-disable-next-line react/no-unstable-nested-components
        tabBarIcon: ({color, size, focused}) => {
          const iconName = ScreenTabIcon[route.name];
          return (
            <Icon
              name={focused ? `${iconName}-solid` : iconName}
              size={size}
              color={color}
            />
          );
        },
      })}>
      <Tab.Screen
        name="ExerciseList"
        component={ExerciseListScreen}
        options={{
          headerTitle: translate('tab.exercise-list'),
          tabBarLabel: translate('tab.exercise-list'),
        }}
      />
      <Tab.Screen
        name="ExerciseShow"
        component={ExerciseShowScreen}
        options={{
          headerTitle: translate('tab.exercise-show'),
          tabBarButton: () => null,
        }}
      />
      <Tab.Screen
        name="Payments"
        component={PaymentsScreen}
        options={{
          headerTitle: translate('tab.payments'),
          tabBarLabel: translate('tab.payments'),
        }}
      />
      <Tab.Screen
        name="Statistics"
        component={StatisticsScreen}
        options={{
          headerTitle: translate('tab.statistics'),
          tabBarLabel: translate('tab.statistics'),
        }}
      />
      <Tab.Screen
        name="Account"
        component={AccountScreen}
        options={{
          headerTitle: translate('tab.account'),
          tabBarLabel: translate('tab.account'),
        }}
      />
    </Tab.Navigator>
  );
}
