import tw from 'twrnc';
import {Text, TouchableOpacity} from 'react-native';
import * as React from 'react';

export default function Button({
  children,
  onPress,
}: {
  children: string;
  onPress: () => void;
}) {
  return (
    <TouchableOpacity
      style={tw`bg-white p-4 rounded-full mb-4`}
      onPress={onPress}>
      <Text style={tw`text-center text-blue-950`}>{children}</Text>
    </TouchableOpacity>
  );
}
