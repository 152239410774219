import tw from 'twrnc';
import {View} from 'react-native';
import React, {ReactElement, ReactNode} from 'react';
import {Circle, Svg} from 'react-native-svg';

export default function Background({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <View style={tw`flex flex-1 items-center flex-col bg-amber-400 relative`}>
      {children}
      <Svg
        width="100"
        height="100"
        viewBox="0 0 80 80"
        fill="none"
        style={tw`absolute left-0 bottom-0 -mb-12 -ml-4`}>
        <Circle opacity="0.7" cx="40" cy="40" r="40" fill="white" />
      </Svg>
      <Svg
        width="45"
        height="45"
        viewBox="0 0 50 50"
        fill="none"
        style={tw`absolute right-0 bottom-16 -mr-4`}>
        <Circle
          opacity="0.3"
          cx="25"
          cy="25"
          r="15"
          stroke="white"
          strokeWidth="16"
        />
      </Svg>
      <Svg
        width="75"
        height="75"
        viewBox="0 0 70 70"
        fill="none"
        style={tw`absolute -right-8 top-1/2 -ml-4 -mt-32`}>
        <Circle
          opacity="0.3"
          cx="35"
          cy="35"
          r="25"
          stroke="white"
          strokeWidth="17"
        />
      </Svg>
    </View>
  );
}
