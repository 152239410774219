import * as React from 'react';
import {ExerciseModel} from '../../models/ExerciseModel';
import {HomeTabScreenProps} from '../../constants/ScreenProps';
import {ExerciseCategoryList} from '../../components/Exercise/ExerciseCategoryList';

export function ExerciseListScreen({
  navigation,
}: HomeTabScreenProps<'ExerciseList'>) {
  const handleSelectExercise = (exercise: ExerciseModel) => {
    navigation.navigate('ExerciseShow', {
      exerciseId: exercise.id,
    });
  };

  return <ExerciseCategoryList onSelectExercise={handleSelectExercise} />;
}
