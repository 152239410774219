import {ClipPath, Defs, G, Rect, Svg} from 'react-native-svg';
import React, {useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';

const numbers = new Map<number, Array<number>>([
  [0, [1, 0, 0, 1, 1, 1, 1]],
  [1, [1, 0, 1, 0, 1, 1, 1]],
  [2, [1, 0, 1, 1, 0, 1, 1]],
  [3, [1, 0, 1, 1, 1, 0, 1]],
  [4, [1, 0, 1, 1, 1, 1, 0]],
  [5, [0, 1, 0, 1, 1, 1, 1]],
  [6, [0, 1, 1, 0, 1, 1, 1]],
  [7, [0, 1, 1, 1, 0, 1, 1]],
  [8, [0, 1, 1, 1, 1, 0, 1]],
  [9, [0, 1, 1, 1, 1, 1, 0]],
]);
const cols = new Map<number, string>([
  [0, '#E6686A'],
  [1, '#CCD148'],
  [2, '#79C6D5'],
  [3, '#E9A15C'],
  [4, '#9BB6D7'],
]);
const numberSum1 = new Map<number, number>([
  [0, 5],
  [1, 0],
  [2, 0],
  [3, 1],
  [4, 2],
  [5, 3],
  [6, 4],
]);
const numberSum2 = new Map<number, number>([
  [0, 0],
  [1, 0],
  [2, 5],
  [3, 6],
  [4, 7],
  [5, 8],
  [6, 9],
]);

export default function Abacus({
  showNumbers = false,
}: {
  showNumbers?: boolean;
}): React.ReactElement {
  const [colNumbers, setColNumbers] = useState<Map<number, number>>(
    new Map([]),
  );

  const getValue = (colIndex: number, numberIndex: number): number => {
    const actualValue: number = colNumbers.get(colIndex) ?? 0;
    const sum1: number = numberSum1.get(numberIndex) ?? 0;
    const sum2: number = numberSum2.get(numberIndex) ?? 0;

    if (numberIndex === 0) {
      return actualValue + 5;
    }

    if (numberIndex === 1) {
      return actualValue - 5;
    }

    return actualValue >= 5 ? sum2 : sum1;
  };

  const setValue = (colIndex: number, value: number): void => {
    setColNumbers(mapColNumbers => {
      mapColNumbers.set(colIndex, value);
      return new Map(mapColNumbers);
    });
  };

  return (
    <View style={{position: 'relative'}}>
      {[...cols.values()].map((color, colIndex) => {
        return (
          <React.Fragment key={colIndex}>
            {(numbers.get(colNumbers.get(colIndex) ?? 0) ?? []).map(
              (fill, numberIndex) => (
                <React.Fragment key={numberIndex}>
                  {fill ? (
                    <TouchableOpacity
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 35,
                        height: 35,
                        backgroundColor: color,
                        left: 20 + colIndex * 68,
                        top: 10 + numberIndex * 49,
                        borderRadius: 20,
                        zIndex: 99,
                      }}
                      onPress={() =>
                        setValue(colIndex, getValue(colIndex, numberIndex))
                      }>
                      <Text>
                        {showNumbers ? getValue(colIndex, numberIndex) : ''}
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                </React.Fragment>
              ),
            )}
            <TouchableOpacity
              style={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 35,
                height: 35,
                left: 20 + colIndex * 68,
                top: 83,
                borderRadius: 20,
                zIndex: 99,
              }}>
              <Text>{showNumbers ? colNumbers.get(colIndex) ?? 0 : ''}</Text>
            </TouchableOpacity>
          </React.Fragment>
        );
      })}
      <Svg
        width="346"
        height="350"
        viewBox="0 0 346 350"
        fill="none"
        style={{position: 'relative'}}>
        {[...cols.values()].map((color, index) => (
          <Rect
            key={index}
            x={37 + index * 68}
            y="5"
            width="2"
            height="340"
            fill="#D9D9D9"
          />
        ))}
        <G clipPath="url(#clip0_21_9769)">
          <Rect
            x="-31.9219"
            y="98"
            width="410.844"
            height="12"
            fill="#D9D9D9"
          />
          <Rect
            x="168.013"
            y="98"
            width="11.5325"
            height="7"
            rx="4"
            fill="#7994B6"
          />
        </G>
        <Rect
          x="2.5"
          y="2.5"
          width="341"
          height="345"
          rx="12.5"
          stroke="black"
          strokeWidth="5"
        />
        <Defs>
          <ClipPath id="clip0_21_9769">
            <Rect
              width="333"
              height="12"
              fill="white"
              transform="translate(7 93)"
            />
          </ClipPath>
        </Defs>
      </Svg>
    </View>
  );
}
