import tw from 'twrnc';
import {Text, View} from 'react-native';
import * as React from 'react';

export default function Alert({children}: {children: string}) {
  return (
    <View style={tw`bg-red-700 p-4 rounded-full mb-4 w-full`}>
      <Text style={tw`text-center text-white`}>{children}</Text>
    </View>
  );
}
