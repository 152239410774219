import * as React from 'react';
import {ReactElement} from 'react';
import tw from 'twrnc';
import {Image, TouchableOpacity, View} from 'react-native';
import {Circle, Svg} from 'react-native-svg';

export default function HeaderLogoRight({
  onPress,
}: {
  onPress?: () => void;
}): ReactElement {
  return (
    <>
      <View style={tw`relative flex items-end w-full mb-6`}>
        <Svg
          width="75"
          height="75"
          viewBox="0 0 70 70"
          fill="none"
          style={tw`absolute left-0 top-40 -ml-4 -mt-32`}>
          <Circle
            opacity="0.3"
            cx="35"
            cy="35"
            r="25"
            stroke="white"
            strokeWidth="17"
          />
        </Svg>
        <View
          style={{
            width: 200,
            height: 200,
            borderBottomLeftRadius: 100,
            backgroundColor: 'white',
            paddingTop: 50,
          }}>
          <TouchableOpacity onPress={onPress} style={tw`flex items-center `}>
            <Image
              source={require('../assets/logo.png')}
              style={{width: 180, height: 120}}
            />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
}
