import {createIntl, createIntlCache} from '@formatjs/intl';
import {I18nManager} from 'react-native';
import * as RNLocalize from 'react-native-localize';

const translations = {
  pl: require('../translations/pl.json'),
} as const;

type Translation = keyof typeof translations;

// fallback if no available language fits
const fallback = {languageTag: 'pl', isRTL: false};

const {languageTag, isRTL} =
  RNLocalize.findBestAvailableLanguage(Object.keys(translations)) ?? fallback;

// update layout direction
I18nManager.forceRTL(isRTL);

const intl = createIntl(
  {
    defaultLocale: 'pl',
    locale: languageTag,
    messages: translations[languageTag as Translation],
  },
  createIntlCache(),
);

type TranslationParams = Parameters<(typeof intl)['formatMessage']>[1];

export const translate = (key: string, params?: TranslationParams) =>
  intl
    .formatMessage({id: key, defaultMessage: translations.pl[key]}, params)
    .toString();

export {RNLocalize};
