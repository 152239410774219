import {
  Image,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import * as React from 'react';
import tw from 'twrnc';
import useSWR from 'swr';
import {swrFetcher} from '../../shared/fetcher';
import {ExerciseResponse} from '../../models/ExerciseModel';
import {HomeTabScreenProps} from '../../constants/ScreenProps';
import Button from '../../components/Button';
import TextCenter from '../../components/TextCenter';
import Checkbox from '../../components/Checkbox';
import Abacus from '../../components/Exercise/Types/Abacus';
import env from '../../constants/env';
import Icon from '../../components/Icon';

export function ExerciseShowScreen({
  navigation,
  route,
}: HomeTabScreenProps<'ExerciseShow'>) {
  const {
    data: exercise,
    isLoading,
    error,
  } = useSWR<ExerciseResponse>(
    `exercises/${route.params.exerciseId}`,
    swrFetcher,
  );

  if (isLoading) {
    return <TextCenter>Loading...</TextCenter>;
  }

  if (!exercise?.data || error) {
    return <TextCenter>Error fetching data</TextCenter>;
  }

  return (
    <ScrollView>
      <View style={tw`flex flex-1 gap-2 text-justify bg-white`}>
        <View style={tw`flex items-center`}>
          {exercise.data.id % 3 === 0 ? (
            <Image
              style={{width: 240, height: 250}}
              source={require('../../assets/exercises/1.png')}
            />
          ) : null}
          {exercise.data.id % 3 === 1 ? (
            <Image
              style={{width: 220, height: 250}}
              source={require('../../assets/exercises/2.png')}
            />
          ) : null}
          {exercise.data.id % 3 === 2 ? (
            <Image
              style={{width: 150, height: 250}}
              source={require('../../assets/exercises/3.png')}
            />
          ) : null}
        </View>
        <View style={tw`flex flex-1 justify-between`}>
          <Text style={tw`text-2xl font-bold text-center mb-4 p-4`}>
            {exercise.data.description_title}
          </Text>
          <Text style={tw`text-xl text-center mb-4 p-4`}>
            {exercise.data.description}
          </Text>
          {exercise.data.exercise_data?.select
            ? exercise.data.exercise_data?.select.map((option: string) => (
                <View
                  key={option}
                  style={tw`flex flex-row gap-2 items-center mb-4 pl-4`}>
                  <Checkbox />
                  <Text style={tw`flex items-center`}>{option}</Text>
                </View>
              ))
            : null}
          {exercise.data.exercise_data?.abacus ? (
            <View style={tw`flex items-center mb-4`}>
              <Abacus />
            </View>
          ) : null}
        </View>
        <View style={tw`flex flex-row justify-between gap-2`}>
          <TouchableOpacity
            style={tw`flex flex-row gap-2 items-center px-4 py-4 rounded-tl-2xl`}
            onPress={() => navigation.goBack()}>
            <Icon name={'arrow-left'} color={'black'} size={20} />
            <Text style={tw`text-black`}>Wyjdź</Text>
          </TouchableOpacity>
          {Platform.OS === 'web' && env.REACT_APP_DEV ? (
            <Button
              onPress={() => {
                // @ts-ignore
                window.open(
                  `http://localhost/nova/resources/exercises/${exercise.data.id}/edit`,
                  '_blank',
                );
              }}>
              Edytuj
            </Button>
          ) : null}
          {exercise.data.next_exercise ? (
            <TouchableOpacity
              style={tw`flex flex-row gap-2 items-center bg-gray-600 px-4 py-4 rounded-tl-2xl`}
              onPress={() =>
                navigation.navigate('ExerciseShow', {
                  exerciseId: exercise.data.next_exercise?.id ?? 0,
                })
              }>
              <Text style={tw`text-white`}>Dalej</Text>
              <Icon name={'arrow-right'} color={'white'} size={20} />
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
    </ScrollView>
  );
}
