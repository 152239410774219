import * as React from 'react';
import {Text, View} from 'react-native';
import tw from 'twrnc';
import {HomeTabScreenProps} from '../../constants/ScreenProps';

export function StatisticsScreen({}: HomeTabScreenProps<'Statistics'>) {
  return (
    <View style={tw`flex flex-1 items-center justify-center`}>
      <Text style={tw`mb-4`}>(Statystyki)</Text>
    </View>
  );
}
