import {RootStackScreenProps} from '../constants/ScreenProps';
import {Text, TextInput, TouchableOpacity} from 'react-native';
import {translate} from '../shared/translation';
import * as React from 'react';
import {useState} from 'react';
import tw from 'twrnc';
import {postFetcher} from '../shared/fetcher';
import Alert from '../components/Alert';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Container from '../components/Container';
import Background from '../components/Background';
import HeaderLogoRight from '../components/HeaderLogoRight';

enum RegisterEmailStep {
  NAME,
  EMAIL,
  PASSWORD,
}

export function RegisterEmailScreen({
  navigation,
}: RootStackScreenProps<'RegisterEmail'>) {
  const [step, setStep] = useState(RegisterEmailStep.NAME);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const onRegister = async () => {
    setError('');

    if (!name || !email || !password) {
      return setError('Fill all fields');
    }

    const response = await postFetcher('auth/register', {
      name,
      email,
      password,
    });

    if (response.code !== 200) {
      return setError(response.data.message || 'Internal error');
    }

    // TODO: move to service
    await AsyncStorage.setItem('token', response.data.token);
    navigation.navigate('Home');
  };

  // TODO: Add validation in next step

  return (
    <Background>
      <HeaderLogoRight onPress={() => navigation.navigate('Start')} />
      <Container>
        {error ? <Alert>{error}</Alert> : null}
        {step === RegisterEmailStep.NAME ? (
          <>
            <Text style={tw`text-2xl font-bold text-white text-center mb-2`}>
              {translate('register-email.enter-name')}
            </Text>
            <TextInput
              autoCapitalize="none"
              style={tw`border rounded-full border-white w-full text-center text-white p-3 mb-3`}
              placeholderTextColor="white"
              placeholder={translate('register-email.enter-name')}
              onChangeText={text => setName(text)}
            />
            <TouchableOpacity
              style={tw`bg-white p-4 rounded-full w-full mb-4`}
              onPress={() => setStep(RegisterEmailStep.EMAIL)}>
              <Text style={tw`text-center text-blue-950`}>
                {translate('register-email.next')}
              </Text>
            </TouchableOpacity>
          </>
        ) : null}
        {step === RegisterEmailStep.EMAIL ? (
          <>
            <Text style={tw`text-2xl font-bold text-white text-center mb-2`}>
              {translate('register-email.enter-email')}
            </Text>
            <TextInput
              autoCapitalize="none"
              style={tw`border rounded-full border-white w-full text-center text-white p-3 mb-3`}
              placeholderTextColor="white"
              placeholder={translate('register-email.enter-email')}
              onChangeText={text => setEmail(text)}
            />
            <TouchableOpacity
              style={tw`bg-white p-4 rounded-full w-full mb-4`}
              onPress={() => setStep(RegisterEmailStep.PASSWORD)}>
              <Text style={tw`text-center text-blue-950`}>
                {translate('register-email.next')}
              </Text>
            </TouchableOpacity>
          </>
        ) : null}
        {step === RegisterEmailStep.PASSWORD ? (
          <>
            <Text style={tw`text-2xl font-bold text-white text-center mb-2`}>
              {translate('register-email.enter-password')}
            </Text>
            <TextInput
              autoCapitalize="none"
              secureTextEntry={true}
              style={tw`border rounded-full border-white w-full text-center text-white p-3 mb-3`}
              placeholderTextColor="white"
              placeholder={translate('register-email.enter-password')}
              onChangeText={text => setPassword(text)}
            />
            <TouchableOpacity
              style={tw`bg-white p-4 rounded-full w-full mb-4`}
              onPress={() => onRegister()}>
              <Text style={tw`text-center text-blue-950`}>
                {translate('register-email.next')}
              </Text>
            </TouchableOpacity>
          </>
        ) : null}
      </Container>
    </Background>
  );
}
