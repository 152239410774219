import * as React from 'react';
import {Text, View} from 'react-native';
import tw from 'twrnc';
import Button from '../../components/Button';
import {HomeTabScreenProps} from '../../constants/ScreenProps';

export function AccountScreen({navigation}: HomeTabScreenProps<'Account'>) {
  return (
    <View style={tw`flex flex-1 items-center justify-center`}>
      <Text style={tw`mb-4`}>(Konto)</Text>
      <Button onPress={() => navigation.navigate('Start')}>Wyloguj</Button>
    </View>
  );
}
