import AsyncStorage from '@react-native-async-storage/async-storage';
import env from '../constants/env';

export const baseURL = env.REACT_APP_API_URL ?? '';

export async function fetcher(
  input: RequestInfo,
  init?: RequestInit,
): Promise<any> {
  const token = await AsyncStorage.getItem('token');
  const defaultInit = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...init,
  };
  const response = await fetch(baseURL + input, defaultInit);

  // TODO: handle json errors
  return {
    code: response.status,
    data: await response.json(),
  };
}

export async function swrFetcher(
  input: RequestInfo,
  init?: RequestInit,
): Promise<any> {
  const f = await fetcher(input, init);
  return f.data;
}

export async function postFetcher(
  input: RequestInfo,
  postData: any,
): Promise<any> {
  return fetcher(input, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(postData),
  });
}
