import {useEffect, useState} from 'react';
import useSWR from 'swr';
import {ExerciseModel, ExercisesResponse} from '../../models/ExerciseModel';
import {swrFetcher} from '../../shared/fetcher';
import TextCenter from '../TextCenter';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import tw from 'twrnc';
import {ExerciseList} from './ExerciseList';
import * as React from 'react';

export function ExerciseCategoryList({
  onSelectExercise,
}: {
  onSelectExercise: (exercise: ExerciseModel) => void;
}) {
  const [categoryId, setCategoryId] = useState(0);

  const {
    data: exerciseCategories,
    isLoading,
    error,
  } = useSWR<ExercisesResponse>('exercise-categories', swrFetcher);

  useEffect(() => {
    setCategoryId(exerciseCategories?.data[0].id ?? 0);
  }, [exerciseCategories]);

  if (isLoading) {
    return <TextCenter>Loading...</TextCenter>;
  }

  if (!exerciseCategories?.data || error) {
    return <TextCenter>Error fetching data</TextCenter>;
  }

  if (!exerciseCategories.data.length) {
    return <TextCenter>No data</TextCenter>;
  }

  return (
    <ScrollView>
      <View style={tw`flex p-4 gap-4`}>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          <View style={tw`flex flex-row gap-4`}>
            {exerciseCategories.data.map(exerciseCategory => (
              <TouchableOpacity
                key={exerciseCategory.id}
                onPress={() => setCategoryId(exerciseCategory.id)}
                style={
                  categoryId === exerciseCategory.id
                    ? tw`border-b-amber-400 border-b-2 pb-2 flex`
                    : tw`flex flex-row`
                }>
                <Text
                  style={
                    categoryId === exerciseCategory.id ? tw`font-bold` : tw``
                  }>
                  {exerciseCategory.title}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
        </ScrollView>
        <View>
          {categoryId ? (
            <ExerciseList
              categoryId={categoryId}
              onSelectExercise={onSelectExercise}
            />
          ) : null}
        </View>
      </View>
    </ScrollView>
  );
}
